/** @jsx jsx */
import { jsx, Image, Select, Input, Text, Flex, Box, Grid } from "theme-ui";
import { useState, useEffect, useCallback, useMemo } from "react";
import { Link, navigate } from "gatsby";
import moment from "moment";
import _, { truncate, get, lowerCase } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useFetchSrcmApi } from "gatsby-plugin-hfn-profile/fetch-srcm-api";
// import { DynamicField } from "@heartfulnessinstitute/react-hfn-forms/dist/DynamicForm";
import { DynamicField } from "sites-common/components/DynamicField";
// components
import { useAlert } from "gatsby-plugin-hfn-profile/alert";
import Layout from "../layouts/Layout";

// utils
import statusIcon from "../utils/statusIcon";
import dateList from "../utils/dateList";

// images
import event1 from "../assets/images/eventImg1.svg";
import event2 from "../assets/images/eventImg2.svg";
import event3 from "../assets/images/eventImg3.svg";
import event4 from "../assets/images/eventImg4.svg";

export default function Index() {
  const [events, setEvents] = useState([]);
  const [regNumber, setRegNumber] = useState("");
  const [searching, setSearching] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterEvents, setFilterEvents] = useState([]);
  const [eventDate] = useState(moment().startOf("month").format("YYYY-MM-DD"));
  const [eventDateList] = useState(dateList());
  const [eventImg] = useState([event1, event2, event3, event4]);
  const { showAlert } = useAlert();
  const { fetchSrcmApi } = useFetchSrcmApi();
  const restrictedStatus = useMemo(
    () => ["event_finished", "event_not_approved", "event_cancelled"],
    []
  );
  const [search, setSearch] = useState({
    type: "",
    search_title: "",
    search_center: "",
    search_country: "",
    month: "",
    sortBy: "",
  });

  // const setConstrains = () => {
  //   if(typeof(eventDetail) === "object"){

  //    return get(eventDetail, "event_json.constraints", [])
  //   }else{
  //     return [];
  //   }
  // }

  const getEventParams = (cm) => {
    let params = {};
    const currentMonth = moment(cm).format("YYYY-MM-DD");
    const futureMonth = moment(currentMonth).add(1, "M").format("YYYY-MM-DD");
    params = {
      write_date__lt: `${futureMonth}T05:30:00Z`,
      write_date__gte: `${currentMonth}T05:30:00Z`,
      page: 1,
      page_size: 200,
      o: "start_datetime",
    };
    return params;
  };

  const getEvents = useCallback(
    async (params) => {
      try {
        await fetchSrcmApi({
          api: `/api/v2/events/`,
          client: "eventsClient",
          methodParams: { ...params },
          method: "GET",
        }).then((eventResponse) => {
          const filteredData = eventResponse.results.filter(
            (item) => !_.includes(restrictedStatus, item.one_status)
          );
          setEvents(filteredData);
          setFilterEvents(filteredData);
        });
      } catch (err) {
        // console.log(err)
      }
    },
    [fetchSrcmApi, restrictedStatus]
  );

  const getEventsByDate = useCallback(
    (ev) => {
      getEvents(getEventParams(ev));
    },
    [getEvents]
  );

  const getEventsInit = useCallback(() => {
    getEvents(getEventParams(eventDate));
  }, [getEvents, eventDate]);

  const setFilterValue = (event) => {
    const { value, name } = event.target;
    setSearch((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    let sortedArray = [];
    if (search.sortBy === "name") {
      sortedArray = events.sort((a, b) => {
        if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
        if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
        return 0;
      });
    } else {
      sortedArray = events.sort((a, b) => {
        if (a.start_datetime.toLowerCase() < b.start_datetime.toLowerCase())
          return 1;
        if (b.start_datetime.toLowerCase() < a.start_datetime.toLowerCase())
          return -1;
        return 0;
      });
    }

    setEvents(sortedArray);
    setFilterEvents(sortedArray);
  }, [search, events]);

  useEffect(() => {
    const fevent = events.filter(
      (item) =>
        lowerCase(get(item, "title", "")).includes(
          lowerCase(search.search_title)
        ) &&
        lowerCase(get(item, "srcm_group.id", "")).includes(
          lowerCase(search.search_center)
        ) &&
        lowerCase(get(item, "venue_country.name", "")).includes(
          lowerCase(search.search_country)
        ) &&
        lowerCase(get(item, "online_registration", "")).includes(
          lowerCase(search.type)
        )
    );
    setFilterEvents(fevent);
  }, [search, events]);

  const doValidateAndSubmit = () => {
    setSearching(true);
    if (regNumber.length === 12) {
      navigate(`events/registrations/${regNumber}`);
    } else {
      const alertInfo = {
        title: "Failed",
        message: `Enter a valid Registration number.`,
        confirm_text: "Okay",
        is_blocking: true,
      };
      showAlert(alertInfo);
    }
    setSearching(false);
  };

  const checkRegistrationDate = (date) => {
    const registrationStartDate = new Date(date).getTime();
    const currentDate = new Date().getTime();
    return registrationStartDate > currentDate;
  };

  useEffect(() => {
    getEventsInit();
  }, [getEventsInit]);

  const renderItems = useMemo(() => {
    return filterEvents?.length > 0 ? (
      <ul>
        {filterEvents.map((item) => {
          const randomImgNumber = Math.floor(Math.random() * 4);
          const linkDetails = statusIcon(item);
          const eventUrl = `events/${item.name}`;
          return (
            <li
              key={item.name}
              id={item.name}
              sx={{
                p: "10px",
                border: "2px solid #f0f0f0",
                gap: [0, 0, "10px"],
              }}
            >
              <div className="overlay-img">
                <div className="overlay" />
                <div
                  className="img"
                  sx={{ display: ["none", "none", "block"] }}
                >
                  <Image
                    src={eventImg[randomImgNumber]}
                    alt="Heartfulness-Events"
                  />
                </div>
              </div>
              <div className="event-info" sx={{ p: ["4px", "4px", "20px"] }}>
                <div>
                  <div className="title-wrapper">
                    <h3 title={item.title}>{item.title}</h3>
                  </div>
                  <p className="event-se-date">
                    {item.start_datetime
                      ? moment(item.start_datetime).format("ll")
                      : ""}{" "}
                    -{" "}
                    {item.end_datetime
                      ? moment(item.end_datetime).format("ll")
                      : ""}
                  </p>
                  <p className="event-preview">
                    {item.event_json && item.event_json.about
                      ? truncate(item.event_json.about, {
                          length: 200,
                          omission: "...",
                        })
                      : ""}
                  </p>
                </div>
                <Flex sx={{ justifyContent: "flex-end" }}>
                  {checkRegistrationDate(item.reg_starts) && (
                    <span sx={{ alignSelf: "center", mr: "10px", mt: "10px" }}>
                      Registration starts on{" "}
                      {moment(item.reg_starts).format("ll")}
                    </span>
                  )}
                  <span
                    className={
                      linkDetails.disabled ? "badge disabled" : "badge"
                    }
                  >
                    <Link to={eventUrl}>
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: linkDetails.icon,
                        }}
                      />
                      {linkDetails.text}
                    </Link>
                  </span>
                </Flex>
              </div>
            </li>
          );
        })}
      </ul>
    ) : (
      <p>No events available for the search criterias</p>
    );
  }, [eventImg, filterEvents]);

  const trimCalendar = useCallback((date) => {
    const timeArray = date.split(" , ");
    return `${timeArray[0].slice(0, 3)} ${timeArray[1]}`;
  }, []);

  return (
    <div>
      <Layout
        pageName="events-list-page"
        headerTitle="Heartfulness Events"
        sidebarVisible
        home
      >
        <div sx={{ variant: "HFNEventList" }}>
          <Flex
            sx={{
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
            }}
          >
            <Box className="card" sx={{ width: ["100%", "45%"], p: "15px" }}>
              <h5 sx={{ fontSize: "16px" }}>
                General Visitor (Kanha) Registrations
              </h5>
              <p sx={{ fontSize: "14px", lineHeight: "16px", my: 2 }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius
                molestias beatae eligendi?
              </p>
              <button
                type="button"
                sx={{
                  bg: "#1ca0f2",
                  color: "#fff",
                  cursor: "pointer",
                  borderRadius: "4px",
                  padding: "6px",
                  mt: 2,
                  width: "100%",
                }}
              >
                Register
              </button>
            </Box>
            <Box className="card" sx={{ width: ["100%", "45%"], p: "15px" }}>
              <h5 sx={{ fontSize: "16px", mb: 2 }}>Search Registrations</h5>
              <Input
                type="text"
                placeholder="Enter Registration Number"
                value={regNumber}
                onChange={(e) => setRegNumber(e.target.value)}
                sx={{
                  mt: 2,
                  mb: 1,
                  fontSize: "12px",
                  border: "1px solid #00000057",
                }}
              />
              <Input
                sx={{
                  bg: "#1ca0f2",
                  color: "#fff",
                  cursor: "pointer",
                  padding: "6px",
                  mt: 3,
                  width: "100%",
                }}
                type="submit"
                value="Search"
                disabled={searching}
                onClick={() => doValidateAndSubmit()}
              />
            </Box>
          </Flex>
          <div className="page-title" sx={{ mt: 3 }}>
            <Flex
              sx={{ justifyContent: "space-between", alignContent: "center" }}
            >
              <h3 sx={{ alignSelf: "center", maxWidth: "70%" }}>
                Kanha Shantivanam - Events Calendar
              </h3>
              <div
                sx={{
                  bg: "#fff",
                  cursor: "pointer",
                  p: "5px",
                  borderRadius: "4px",
                  m: "4px",
                  mb: "16px",
                  border: "1px solid #767596",
                }}
                onClick={() => setShowFilter(!showFilter)}
                role="button"
                tabIndex={0}
                onKeyDown={() => setShowFilter(!showFilter)}
              >
                <FontAwesomeIcon icon={faFilter} color="#767596" /> Filter
              </div>
            </Flex>
            {showFilter && (
              <Grid columns={[1, 3, 3, 6]}>
                <div className="search">
                  <Input
                    name="search_title"
                    onChange={(ev) => {
                      setFilterValue(ev);
                    }}
                    placeholder="Title"
                    sx={{ border: "1px solid #00000057" }}
                  />
                </div>
                <div className="search" sx={{ position: "relative" }}>
                  <DynamicField
                    name="srcm_group"
                    value={search.search_center}
                    onChange={(value) => {
                      setSearch((prevState) => ({
                        ...prevState,
                        search_center: value,
                      }));
                    }}
                    type="city_id"
                    sx={{
                      fontSize: "15px",
                      border: "1px solid #00000057",
                      ul: { bg: "red" },
                    }}
                    placeholder="Ashram / Center"
                  />
                  {/* <DynamicField
                  name="srcm_group"
                  value={search.search_center}
                  onChange={(value) => {
                    setSearch((prevState) => ({
                      ...prevState,
                      search_center: value,
                    }));
                  }}
                  type="srcm_center_id"
                  props={{
                    placeholder: "Ashram / Center",
                    styles: {
                      root: {
                        maxWidth: 200,
                        border: "1px solid",
                        borderRadius: "3px",
                      },
                    },
                  }}
                /> */}
                </div>
                <div className="search">
                  <DynamicField
                    name="search_country"
                    value={search.search_country}
                    onChange={(value) => {
                      setSearch((prevState) => ({
                        ...prevState,
                        search_country: value.name,
                      }));
                    }}
                    type="country_idname"
                    sx={{ fontSize: "15px", border: "1px solid #00000057" }}
                    placeholder="Country"
                  />
                  {/* <DynamicField
                  name="search_country"
                  value={search.search_country}
                  onChange={(value) => {
                    setSearch((prevState) => ({
                      ...prevState,
                      search_country: value,
                    }));
                  }}
                  type="country"
                  props={{
                    placeholder: "Country",
                    styles: {
                      root: {
                        maxWidth: 200,
                        border: "1px solid",
                        borderRadius: "3px",
                      },
                    },
                  }}
                /> */}
                </div>
                <div className="search">
                  <Select
                    defaultValue={eventDate}
                    name="type"
                    onChange={(ev) => {
                      setFilterValue(ev);
                    }}
                    value={search.type}
                    sx={{ border: "1px solid #00000057" }}
                  >
                    <option value="" disabled sx={{ color: "#848484" }}>
                      Type
                    </option>
                    <option key="online" value>
                      Online
                    </option>
                    <option key="offline" value={false}>
                      Kanha Shantivanam - Events
                    </option>
                  </Select>
                </div>
                <div className="select" sx={{ position: "relative" }}>
                  <span sx={{ position: "absolute", top: "8px", left: "10px" }}>
                    <FontAwesomeIcon icon={faCalendarPlus} color="#767596" />
                  </span>
                  <Select
                    name="month"
                    defaultValue={eventDate}
                    onChange={(ev) => {
                      getEventsByDate(ev.target.value);
                    }}
                    sx={{
                      textIndent: "22px",
                      border: "1px solid #00000057",
                      width: ["100%"],
                    }}
                  >
                    {eventDateList.map((item) => {
                      return (
                        <option key={item.id} value={item.value}>
                          {trimCalendar(item.label)}
                        </option>
                      );
                    })}
                  </Select>
                </div>
                <div className="search" sx={{ position: "relative" }}>
                  <Select
                    defaultValue={eventDate}
                    name="sortBy"
                    onChange={(ev) => {
                      setFilterValue(ev);
                    }}
                    value={search.sortBy}
                    sx={{ border: "1px solid #00000057" }}
                  >
                    <option value="" disabled sx={{ color: "#848484" }}>
                      Sort By
                    </option>
                    <option key="name" value="name">
                      Name
                    </option>
                    <option key="date" value="start_datetime">
                      Date
                    </option>
                  </Select>
                </div>
              </Grid>
            )}
          </div>
          {renderItems}
        </div>
      </Layout>
    </div>
  );
}
