// utils
import moment from "moment";

// TO BE OPTIMIZED
const dateList = () => {
  const previousMonth = () => {
    const pm = [];

    for (let i = 0; i < 4; i += 1) {
      const cm = moment()
        .startOf("month")
        .subtract(4 - (i + 1), "M")
        .format("YYYY-MM-DD");
      pm.push({
        id: `pm${i}`,
        label: `${moment(cm).format("MMMM")} , ${moment(cm).format("YYYY")}`,
        value: cm,
      });
    }

    return pm;
  };

  const nextMonth = () => {
    const nm = [];

    for (let i = 0; i < 3; i += 1) {
      const cm = moment()
        .startOf("month")
        .add(i + 1, "M")
        .format("YYYY-MM-DD");
      nm.push({
        id: `cm${i}`,
        label: `${moment(cm).format("MMMM")} , ${moment(cm).format("YYYY")}`,
        value: cm,
      });
    }

    return nm;
  };

  return [...previousMonth(), ...nextMonth()];
};

export default dateList;
